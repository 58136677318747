:root {
  --primary-color: white;
  --secondary-color: #0088a6;
  --blue-lis: #092e3b;
  --lis-ligthblue: #00b1e2;
  --aedas-green: #00b176;
  --toastify-color-success: #00b176 !important;
  --toastify-color-error: #b1003b !important;
  --aedas-red: #b1003b;
  --font-pay: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  --lis-title: "lis-title";
  --lis-body: "lis-body";
  --iconColor: black;
  --iconButtonSelected: #0088a6;
}

#root > div.row > div.w-100 > div > button > svg > path{
  fill:white !important
}


body {
  font-family: var(--lis-title), var(--lis-body);
  /* background-color: #8080801a !important; */
  background-color: #f8f8f8 !important;
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: "lis-body";
  src: url(/public/Assets/Fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "lis-title";
  src: url(/public/Assets/Fonts/WorkSans-Medium.ttf);
}

.row {
  --bs-gutter-x: 0 !important;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 5rem;
  text-transform: uppercase;
}
