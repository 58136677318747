.single-news-container {
    width: 95%;
    margin: 0 auto;
}

.single-news-container > img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 2rem;
    border-radius: 20px;
}

.single-news-container p.subtitle {
    color: #6b7983;
}

/* .content-from-wordpress * {
    all: unset;
} */