.section-box.footer-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    width: 95%;
    margin: 2rem auto 0;
}

.footer-links .white-box  {
    width: calc( (95% - 5*2rem) / 6 );
    max-width: 180px;
    padding: 0;
    box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 10%);
}

.footer-links .white-box img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 110px;
}

.footer-links .white-box p {
    text-align: center;
    padding: 0.8rem;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.footer-links .white-box a {
    text-decoration: none;
    color: #051c2c;
}

.footer-info {
    text-align: center;
    padding: 1rem;
    width: 95%;
    margin: 2rem auto;
}

.footer-info p {
    margin-bottom: 0;
}
