.news-list {
    display: flex;
    align-items: center;
}

.section-box.news-list {
    margin-bottom: 2rem;
    padding-right: 1.5rem;
    align-items: stretch;
}

.archive .image-container {
    width: 45%;
    height: auto;
}

.archive .news-title {
    padding: 2rem 0;
}

.news-list .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.news-list p {
    margin-bottom: .5rem;
    border-left: 0;
}

.news-list p.rol {
    color: #6b7983;
    font-style: italic;
    font-size: .8rem;
    margin: 0 0 1rem 0;
}

.news-list p.subtitle {
    color: #6b7983;
    margin-bottom: 1rem;
    max-height: 2.8em;
}

@media all and (max-width: 768px) {

    .archive .news-title p {
        width: 100%;
        max-width: 100%;
        text-align: left;
    }

    .archive .section-box.news-list {
        min-height: 200px;
    }

    .archive .news-title {
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;
        height: auto;
        margin-top: 1.5rem;
    }

    .archive .image-container {
        width: 45%;
    }
}

@media all and (max-width: 480px) {
    .news-container.archive {
        width: 90%;
        margin: 0 auto;
    }

    .archive .section-box.news-list {
        flex-direction: column;
        padding: 0;
    }

    .archive .image-container {
        width: 100%;
        height: 200px;
    }

    .archive .image-container img {
        border-radius: 20px 20px 0 0;
    }

    .archive .news-list p.subtitle  {
        max-height: max-content;
    }
}
