.sidebar-container {
    width: 25%;
    margin: 0 1rem;
}

.my-apps {
    background-color: #e9eaec;
    min-height: 470px;
    border-radius: 30px;
    width: 90%;
    max-width: 100%;
    margin: 0 auto 2rem;
}

.my-apps h4 {
    font-size: 1.1rem;
}

.imgPersonHeaderSmall {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 100%;
}
  
.apps-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
}

.apps-box a {
    text-decoration: none;
    color: #051c2c;
}

.apps-box img {
    text-align: center;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.section-box.logos {
    padding: 2rem;
    width: 90%;
    max-width: 100%;
    margin: 1rem auto;
}

.section-box.logos p {
    text-transform: uppercase;
    text-align: center;
}

.logos .images-container {
    display: flex;
    justify-content: space-around;
}

.react-calendar { 
    width: 90%;
    background-color: #e9eaec;
    border-radius: 20px;
    color: #092e3b;
    font-family: var(--lis-title), var(--lis-body);
    height: 350px;
    line-height: 1em;
    max-width: 100%;
    margin: 0 auto 2rem;
    padding: 1.5rem;
    border: 0px solid #a0a096;
}

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus {
    background-color: var(--lis-ligthblue);
}

.react-calendar__tile--now {
    background: var(--aedas-green);
    border-radius: 15px;  
}

.react-calendar__tile--now:enabled:hover, 
.react-calendar__tile--now:enabled:focus {
    background: rgba(0, 177, 118, 0.4);
}

.react-calendar__tile--rangeEnd {
    background-color: var(--lis-ligthblue);
    color: #fff;
    border-radius: 15px;
}

.react-calendar__month-view__weekdays {
    font-size: 0.6em !important;
}