.main-container  {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 95%;
    margin: 1rem auto;
}

.header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    align-items: center;
    width: 95%;
    margin: 1rem auto 0;
}

.header h2 {
    margin-left: 1rem;
    font-weight: 600;
    text-transform: capitalize;
}

.person-data-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 25%;
}

.person-data-box p {
    margin-left: 1rem !important;
}

.section-box {
    background-color: #e9eaec;
    border-radius: 20px;
}

.white-box {
    background-color: white;
    padding: 1.5rem;
    flex-grow: 1;
    border-radius: 20px;
}

.white-box p {
    text-align: left;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}

.two-columns {
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
}
 
.two-columns .section-box {
    width: calc(50% - 1rem/2);
}

.two-columns .section-box h4 {
    font-size: 1.1rem;
    transition: all 0.4s ease;
}

.two-columns .section-box h4:hover {
    font-size: 1.20rem;

}

.two-columns .section-box section {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    margin-bottom: 2rem;
}

.two-columns .section-box section .MuiTabs-root.css-1ujnqem-MuiTabs-root {
    margin: -4rem 0 0 35%;
}

.inner-section {
    display: flex;
    background-color: #fff;
    border-radius: 20px;
    min-height: 230px !important;
    flex-grow: 1 !important;
}

.two-columns .section-box section .white-box {
    display: flex;
    flex-direction: column;
    border-radius: 0 20px 20px 0;
    width: 60%;
    background-color: transparent;
}

.two-columns .section-box section .white-box p {
    flex-grow: 1;
}

.two-columns .section-box img {
    border-radius: 20px 0 0 20px;
    max-height: 15em;
}

.two-columns .section-box section .white-box .title-container {
    justify-content: flex-start;
}

.two-columns .section-box section.people .white-box .title-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.two-columns .section-box section.people .white-box .title-container h5 {
    align-self: flex-start;
}

.two-columns .section-box img {
    width: 40%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 0px 5px rgba(9, 46, 59, 0.2);
}


@media all and (min-width: 1024px) {
    .featured-news-container .css-1ujnqem-MuiTabs-root {
        margin-left: 65%;
    }
}

@media all and (max-width: 1024px) {
    .react-calendar {
        display: none;
    }

    .news-by-categories,
    .two-columns {
        flex-direction: column;
    }

    .news-by-categories,
    .two-columns {
        width: 95%;
        margin: 2rem  auto;
    }

    .category-news,
    .two-columns .section-box {
        width: 100%;
    }

    .section-box.footer-links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 0;

    }

    .footer-links .white-box {
        max-width: 90%;
        width: 80%;
        margin: 0 auto;
    }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .apps-box,
    .images-container {
        display: flex;
        flex-direction: column;
    }

    .images-container {
        align-items: center;
        gap: 2rem;
    }

    .images-container img {
        width: 80% !important;
    }

    .featured-news-container .css-1ujnqem-MuiTabs-root {
        margin-left: 60%;
    }
}

@media all and (max-width: 768px) {
    .main-container,
    .header {
        flex-direction: column-reverse;
    }

    .header h2 {
        align-self: flex-start;
    }

    .person-data-box {
        align-self: flex-end;
    } 
    
    .person-data-box p,
    .person-data-box svg {
        display: none;

    }

    .sidebar-container {
        width: 430px;
        margin: 0 auto;
    }

    .news-container {
        width: 100%;
    }

    .featured-news-container {
        margin: 0 auto;
        width: 95%;
    }

    .section-box.featured-news {
        flex-direction: column;
        padding-right: 0;

    }

    .section-box.featured-news img{
        width: 100%;
        border-radius: 20px 20px 0 0;
        margin-bottom: 1rem;
    }

    .news-title{
        text-align: center;
        margin: 0 1rem;
        padding-bottom: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        height: 7em;
    }
  
    .news-title p {
        border-left: 0;
        max-width: 80%;
        width: 70%;
        margin-bottom: 0;
    }

    .featured-news-container .css-1ujnqem-MuiTabs-root {
        margin: -4rem 0 0 0;
    }

    .two-columns .section-box section .MuiTabs-root.css-1ujnqem-MuiTabs-root {
        margin: 0;
    }

    .section-box.footer-links {
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-links .white-box {
        max-width: 80%;
        width: 80%;
        margin: 0 auto;
    }
}

@media all and (max-width: 480px) {
    .sidebar-container {
        width: 95%;
        margin: 0 auto;
    }

    .news-title{
        flex-direction: column;
        margin: 0;
        height: auto;
        align-items: center;
    }

    .news-title p {
        width: 95%;
        max-width: 90%;
        margin: 0 auto;
    }

    .news-title .button {
        margin-bottom: 0.5rem;
    }

    .section-box.footer-links {
        grid-template-columns: 1fr;
        width: 80%;
    }
}

