.news-container {
    width: 70%;
}

.featured-news-container {
    margin-bottom: 4rem;
}

.featured-news-container .css-1ujnqem-MuiTabs-root {
    z-index: 10;
    margin: -5rem 0 0 25rem;
}

.section-box.featured-news {
    padding-right: 1.5rem;
}

.featured-news {
    display: flex;
    align-items: center;
}

.featured-news img {
    width: 65%;
    min-width: 65%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.news-title {
    margin-bottom: 0;
    margin-left: 2rem;
}

.news-title p {
    padding-left: 0.5rem;
    border-left: 2px solid #051c2c;
    font-weight: 600;
}

.button {
    font-family: var(--lis-title) !important;
    background-color: var(--blue-lis) !important;
    color: white !important
}

.load-more {
    margin-top: 1rem !important;
    font-family: var(--lis-title) !important;
    font-size: .5rem;
    color: var(--blue-lis) !important;
    outline: 0 !important;
}

.load-more:hover {
    border: 0 solid transparent !important;
    background-color: rgba(9, 46, 59, 0.1) !important;
    color: var(--blue-lis) !important;
}

.news-by-categories {
    display: flex;
    margin-top: 2rem;
    min-height: 470px;
}

.category-news {
    width: 33%;
    margin: 0 auto;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.category-news .button {
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1.1rem;
}

.category-news .button svg {
    fill: #fff !important;
    font-size: 25px;
}

.category-news .white-box p {
    height: 12em;
    overflow: hidden;
}

.title-container {
    border-bottom: 2px solid #eff2f2;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin-bottom: 1rem;
    height: 4em;
}

.title-container h5 {
    font-size: 1.1rem;
    align-self: center;
    max-height: 3.6em;
    overflow: hidden;
}

.title-container img {
    width: 40px;
    margin: 0 0.8rem 0.8rem 0;
    height: 40px;
    align-self: center;
}

.css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
}

.css-heg063-MuiTabs-flexContainer {
    justify-content: center;
}

.news-container button:not(:disabled) {
    min-width: 20px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none;
}

.css-1t4lqmc-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--blue-lis) !important;
    padding: 0 !important;
    min-width: 30px !important;
}

.css-1t4lqmc-MuiButtonBase-root-MuiTab-root {
    color: var(--blue-lis) !important;
    margin-right: 0.5rem !important;
    border-bottom: 1px solid grey !important;
}
