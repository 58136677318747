.comment-container {
    width: 95%;
    margin: 0 auto;
}

.comment-container hr {
    margin-top : 3rem !important;
}
.comment-container form {
    text-align: end;
    padding-bottom: 1rem;
}

.comment-container button {
    margin: 0.5rem 1rem 1rem 0;
}

.css-1gzyby-MuiInputBase-root-MuiFilledInput-root {
    font-family: var(--lis-title), var(--lis-body) !important;
    background-color: #fff !important;
}

.comment-box {
    display: flex;
    padding: 1rem 1rem 0;
    flex-direction: column;
}

.comment-box.response {
    /* justify-content: center; */
    text-align: start;
    background-color: #e8eaeb;
    border-radius: 20px;
    /* margin-bottom: 2rem; */
    /* min-height: 10rem; */
}


.comment-box div.comment-header {
    align-items: center;
    display: flex;
    align-items: center;
}


.comment-box div.comment-header p {
    margin-bottom: 0;
}

.comment-box div:not(:last-child) {
    display: flex;
    margin-bottom: 1rem;
    /* align-items: center; */
}

.comment-box.new {
    flex-direction: row;
}

.comment-box img {
    border: 3px solid var(--lis-ligthblue) !important;
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 1rem;
}

span.comment-content p {
    display: inline !important;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1gzyby-MuiInputBase-root-MuiFilledInput-root {
    border-radius: 20px !important;
}

.css-1gzyby-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom-color: transparent !important
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--blue-lis) !important;
}

.new-comment .css-7209ej-MuiInputBase-input-MuiFilledInput-input {
    height: 4.8em !important;
}

.css-1gzyby-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom-color: transparent !important ;
}

button.response-button {
    color: var(--blue-lis);
    z-index: 10;
    margin-top: -4rem;
    margin-left: calc(90% - 3rem);
}